<template>
    <page-content>

        <common-table
                ref="table"
                path="web/hardware/device"
                :columns="columns">

            <template slot="search">
<!--                <a-button ghost type="primary" @click="add">新增</a-button>-->
            </template>

            <template slot="online" slot-scope="{record}">
                <a-tag v-if="record.online" color="green">上线</a-tag>
                <a-tag v-else color="cyan">离线</a-tag>
            </template>

            <template slot="type" slot-scope="{record}">
                <a-tag v-if="record.type==1" color="red">连接中断</a-tag>
                <a-tag v-if="record.type==2" color="orange">上报超时</a-tag>
                <a-tag v-if="record.type==3" color="green">服务器重启</a-tag>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-view @click="view(record)"></action-view>
            </template>

        </common-table>


    </page-content>
</template>
<script>

    export default {
        components: {
        },
        data() {
            return {}
        },

        computed: {
            columns() {
                return [
                    {
                        title: '设备MAC',
                        dataIndex: 'mac',
                    },
                    {
                        title: 'IP',
                        dataIndex: 'ip',
                    },
                    // {
                    //     title: '城市',
                    //     dataIndex: 'city',
                    // },
                    {
                        title: '厂商',
                        dataIndex: 'manufacturer',
                    },
                    {
                        title: '型号',
                        dataIndex: 'model'
                    },
                    // {
                    //     title: '系统版本号',
                    //     dataIndex: 'sdkVersionCode'
                    // },
                    {
                        title: '安卓版本',
                        dataIndex: 'sdkVersionName'
                    },
                    {
                        title: 'androidID',
                        dataIndex: 'androidID'
                    },
                    {
                        title: 'uniqueDeviceId',
                        dataIndex: 'uniqueDeviceId'
                    },
                    {
                        title: '上次启动时间',
                        dataIndex: 'updatedAt'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 60,
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add(){
                this.$refs.cameraEdit.show()
            },
            view(record) {

            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
